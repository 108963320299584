import React, { useEffect, useState } from "react";
import Layout from "../../Layout";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import data from "../../../data/calendar";
import YoutubeOverlay from "../../YoutubeOverlay";
import { Link } from "gatsby";
import SEO from "../../seo";

export default function SchoolCalendarPage() {
  const [currentMonth, setCurrentMonth] = useState(data[0].m + "," + data[0].y);
  const [showYoutube, setShowYoutube] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const displayData = data.filter(
    (item) =>
      currentMonth.toLowerCase() === (item.m + "," + item.y).toLowerCase()
  );

  function toggleYoutube() {
    setShowYoutube(!showYoutube);
  }

  const months = [
    ...new Set(
      data.map((item) => {
        return item.m + "," + item.y;
      })
    ),
  ];

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Layout>
      <SEO
        title="School Calender | Chinmaya Vidyalaya Vasant Vihar | Chinmaya Mission"
        description="View the updated school calender of Chinmaya School Vasant Vihar Delhi for latest events, reports and scheduled activities"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="Chinmaya Mission, schools in delhi, cbse schools in delhi,best schools in delhi,Chinmaya Vidyalaya Vasant Vihar,chinmaya vidyalaya,chinmaya mission school,chinmaya mission delhi"
      />
      <div className="calendar">
        <h1 className="heading">School Calendar</h1>

        <div className="calendar__news">
          <YoutubeOverlay
            show={showYoutube}
            onClickClose={toggleYoutube}
            src={youtubeUrl}
          />

          <div className="calendar__news__content">
            <div className="calendar__news__header">
              <div className="calendar__news__header__card hide-scrollbar noselect">
                {months.map((item, i) => (
                  <div
                    className={`calendar__news__header__card__date ${
                      currentMonth === item &&
                      "calendar__news__header__card__date--selected"
                    }`}
                    role="button"
                    tabIndex="-1"
                    onClick={() => setCurrentMonth(item)}
                  >
                    <div className="calendar__news__header__card__date__m">
                      {item.split(",")[0]}
                    </div>
                    <div className="calendar__news__header__card__date__y">
                      {item.split(",")[1]}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {displayData.map((item) => (
              <div className="calendar__news__body">
                <div className="row calendar__news__body__row">
                  <div className="col col-12 col-xl-4">
                    <div className="calendar__news__body__left">
                      <h2 className="calendar__news__body__left__title">
                        {item.title}
                      </h2>

                      <div className="calendar__news__body__left__date">
                        {item.fullDate}
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            item.text.length > 650
                              ? item.text.slice(0, 650) + "....."
                              : item.text,
                        }}
                      ></p>

                      {/* <Link to="/" className="btn-dark">
                        Read More
                      </Link> */}
                      {item.download && (
                        <a
                          target="_blank"
                          href={item.download}
                          className="btn-dark"
                          download
                        >
                          Download PDF
                        </a>
                      )}

                      <div className="s_icons">
                        <div className="s_box">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${item.videoSrc}`}
                            target="_blank"
                            className=""
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </div>
                        <div className="s_box">
                          <a
                            href={`https://twitter.com/intent/tweet?url=${item.videoSrc}`}
                            target="_blank"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </div>
                        <div className="s_box">
                          <a
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${item.videoSrc}`}
                            target="_blank"
                            className=""
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-xl-8">
                    <div className="calendar__news__body__right">
                      {!item.isVideo ? (
                        <div className="slidercontainer">
                          <Slider {...settings}>
                            {item.images.map((item, i) => (
                              <div className="calendar__news__body__right__img">
                                <img
                                  key={`herodesk-${i}`}
                                  alt="Hero Image"
                                  loading="eager"
                                  src={item}
                                ></img>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      ) : (
                        <div className="calendar__news__body__right__video">
                          <div className="calendar__news__body__right__video__overlay">
                            <div
                              onClick={() => {
                                setYoutubeUrl(item.videoSrc);
                                toggleYoutube();
                              }}
                              role="button"
                              tabIndex="-2"
                              className="calendar__news__body__right__video__playbutton"
                            >
                              <i class="fa fa-play" aria-hidden="true"></i>
                            </div>
                          </div>

                          <div className="calendar__news__body__right__img">
                            <img alt="" src={item.images[0]}></img>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
